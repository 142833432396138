import { useMemo, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Modal, Input as CustomInput, Flex, ErrorMessage } from '@kiper/ui';
import {
  eventPutOnTechnicalReview,
  eventCreateServiceOrderAndFinish,
} from '@kiper/monitoring-graphql/event';
import { useSwal } from '@kiper/hooks';
import { getUserLang } from '@kiper/fns';
import { sendAmplitudeData } from '../../../services/amplitude';
import { useFormatApolloErrorHandler } from '../../../hooks';
import { EventAttendanceContext } from '../../../store';
import { UntreatedEventSelect } from '../../Select';
import { Text, Button, Form } from './styles';

const TechnicalReviewEventModal = ({ onToggle, event, createServiceOrder }) => {
  const { toast } = useSwal();
  const { onError } = useFormatApolloErrorHandler();
  const [t] = useTranslation('modals/technical-review-event-modal');

  const eventAttendanceContext = useContext(EventAttendanceContext);

  const {
    eventOnTechnicalReviewInAttendance,
    loading,
    stop: { dropEvent },
  } = eventAttendanceContext;

  const [
    putEventOnTechnicalReview,
    { loading: loadingPutEventOnTechnicalReview },
  ] = useMutation(eventPutOnTechnicalReview, {
    onError,
  });

  const [
    createServiceOrderAndFinish,
    { loading: loadingCreateServiceOrderAndFinish },
  ] = useMutation(eventCreateServiceOrderAndFinish, {
    onError,
  });

  const technicalReviewStepTextSufix = createServiceOrder
    ? 'create-service-order'
    : 'technical-review';

  const onSubmit = values => {
    const untreatedEventBody = {
      message: values.message,
      untreatedEventOptionId: values.untreatedEvent.id,
      textLog: values.textLog,
    };

    const submitUntreatedEvent = eventOnTechnicalReviewInAttendance
      ? createServiceOrderAndFinish
      : putEventOnTechnicalReview;

    submitUntreatedEvent({
      variables: {
        eventId: event.eventId,
        untreatedEventBody,
      },
    })
      .then(() => dropEvent().then(() => onToggle()))
      .then(() =>
        toast.fire({
          icon: 'success',
          title: t(`success-message-${technicalReviewStepTextSufix}`),
        }),
      );
  };

  const { setFieldValue, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      message: '',
      textLog: '',
      untreatedEvent: null,
      untreatedEventOptionId: null,
    },
    validationSchema: yup.object({
      message: yup
        .string()
        .trim()
        .min(5, t('common:feedback.min', { number: 5 }))
        .required(t('common:feedback.required-field')),
      textLog: yup.string().trim(),
      untreatedEventOptionId: yup.number(),
      untreatedEvent: yup
        .object({
          id: yup.number(),
          description: yup.string(),
        })
        .required(t('common:feedback.required-field'))
        .typeError(t('common:feedback.required-field')),
    }),
    onSubmit,
  });

  useEffect(() => {
    const reportInStorage = localStorage.getItem(event?.eventId);
    if (reportInStorage) setFieldValue('textLog', reportInStorage);
  }, []);

  const lang = useMemo(() => getUserLang().split('-')[0], []);

  useEffect(() => {
    const { untreatedEventOption, message } =
      eventOnTechnicalReviewInAttendance?.onTechnicalReview || {};

    if (untreatedEventOption) {
      setFieldValue('untreatedEvent', {
        id: untreatedEventOption.id,
        description: untreatedEventOption.description[lang],
      });
      setFieldValue('untreatedEventOptionId', untreatedEventOption.id);

      setFieldValue('message', message || '');
    }
  }, [eventOnTechnicalReviewInAttendance]);

  const { untreatedEventError, messageError } = useMemo(
    () => ({
      untreatedEventError: touched?.untreatedEvent && !!errors?.untreatedEvent,
      messageError: touched?.message && !!errors?.message,
    }),
    [errors, touched],
  );

  const submitting =
    loadingPutEventOnTechnicalReview ||
    loadingCreateServiceOrderAndFinish ||
    loading;

  const handleChangeUntreatedEvent = untreatedObject => {
    if (!untreatedObject) {
      setFieldValue('untreatedEventOptionId', null);
      return setFieldValue('untreatedEvent', '');
    }

    setFieldValue('untreatedEvent', untreatedObject);
    setFieldValue('untreatedEventOptionId', untreatedObject.id);

    return sendAmplitudeData(
      'untreated event changed choice to technical review',
      {
        eventId: event?.eventId,
        untreatedId: untreatedObject.id,
        untreatedDescription: untreatedObject.description,
      },
    );
  };

  return (
    <Modal
      title={t(`title-${technicalReviewStepTextSufix}`)}
      open
      toggle={onToggle}
      size="md"
    >
      <Flex flexDirection="column">
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection="column" gridGap="16px">
            <Flex flexDirection="column">
              <Text>{t('untreated-event-option-label')}</Text>
              <UntreatedEventSelect
                fullwidth
                isClearable={false}
                name="untreatedEvent"
                value={values.untreatedEvent}
                onChange={e => handleChangeUntreatedEvent(e)}
                placeholder={t('untreated-options-placeholder')}
              />
              {untreatedEventError && (
                <ErrorMessage>{errors.untreatedEvent}</ErrorMessage>
              )}
            </Flex>
            <Flex flexDirection="column" width="100%">
              <Text>{t('what-message')}</Text>
              <CustomInput
                type="textarea"
                placeholder={t('message-placeholder')}
                onChange={setFieldValue}
                name="message"
                value={values.message}
                rows={4}
              />
              {messageError && <ErrorMessage>{errors.message}</ErrorMessage>}
            </Flex>
            <Flex justifyContent="flex-end">
              <Button
                type="button"
                color="neutral"
                variant="outlined"
                onClick={onToggle}
              >
                {t('buttons:cancel')}
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                loading={submitting}
                disabled={submitting}
              >
                {submitting
                  ? t(`loading-submit-to-${technicalReviewStepTextSufix}`)
                  : t(`confirm-submit-to-${technicalReviewStepTextSufix}`)}
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

export default TechnicalReviewEventModal;

TechnicalReviewEventModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  createServiceOrder: propTypes.bool.isRequired,
};
