import { useMemo, useEffect, useContext, useState } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { queries } from '@kiper/monitoring-graphql/attendance_default_message';
import { Mention, Box, Text, KiperButton } from '@kiper/ui';
import { FiCheck, FiClock } from 'react-icons/fi';
import { MdOutlineConstruction, MdOutlineEngineering } from 'react-icons/md';
import { useDebounce, useToggle } from '@kiper/hooks';
import { formatDateTime } from '@kiper/fns';
import { sendAmplitudeData } from '../../../../services/amplitude';
import {
  WaitingEventModal,
  TechnicalReviewEventModal,
} from '../../../../components/Modals';
import { EventAttendanceContext, TriageContext } from '../../../../store';
import { Button, DropIcon, Form } from './styles';

const EventRecord = ({
  drop,
  event,
  values,
  errors,
  touched,
  setFieldValue,
  handleSubmit,
  usersModalOpen,
  loading,
}) => {
  const [t] = useTranslation('event_attendance');
  const theme = useTheme();
  const debounce = useDebounce({ delay: 600 });
  const [openWaitingEventModal, toggleWaitingEventModal] = useToggle(false);
  const [
    openTechnicalReviewEventModal,
    toggleTechnicalReviewEventModal,
  ] = useToggle(false);
  const [isEventTechnical, setIsEventTechnical] = useState(false);
  const { handleClear } = useContext(TriageContext);
  const { eventOnTechnicalReviewInAttendance } = useContext(
    EventAttendanceContext,
  );

  const [fetch, { data }] = useLazyQuery(queries.list);

  const suggestionData = useMemo(() => {
    if (data?.attendanceDefaultMessages.length) {
      return data.attendanceDefaultMessages.map(suggestion => ({
        ...suggestion,
        display: `${suggestion.shortCut} - ${suggestion.message}`,
      }));
    }
    return [
      {
        id: 0,
        display: t('details.event-logger.empty-state'),
        disabled: true,
      },
    ];
  }, [data]);

  const onSuggestionSelected = (id, display) => {
    const newDisplay = id ? display.split('- ')[1] : '';
    const date = formatDateTime(new Date(), 'DD/MM/YYYY HH:mm:ss');
    return `${date}: ${newDisplay}`;
  };

  const handleChange = (e, newValue, newPlainTextValue) => {
    setFieldValue('report', newPlainTextValue);
  };

  useEffect(() => {
    debounce(() => {
      if (values?.report) {
        localStorage.setItem(event.eventId, values.report);
      } else {
        localStorage.removeItem(event.eventId);
      }
    });
  }, [values.report]);

  const handleDropEvent = () => {
    drop().then(() => {
      handleClear();
      sendAmplitudeData('drop attendance', {
        partner: event.partner?.email,
        condominium: event.condominium?.name,
        eventCode: event.eventType,
        eventId: event.eventId,
      });
    });
  };

  useEffect(() => {
    if (
      event?.eventSettings?.eventType !== null &&
      event?.condominium?.personContext?.id
    ) {
      fetch({
        variables: {
          eventType: event.eventSettings?.eventType,
          condominiumId: event.condominium.personContext.id,
        },
      });
    }
  }, [event]);

  useEffect(() => {
    if (values?.report) return;

    const reportInStorage = localStorage.getItem(event?.eventId);

    if (eventOnTechnicalReviewInAttendance && !reportInStorage) {
      const { textLog = '' } =
        eventOnTechnicalReviewInAttendance?.onTechnicalReview || {};

      if (textLog) setFieldValue('report', textLog);
    }

    if (reportInStorage) setFieldValue('report', reportInStorage);
  }, [eventOnTechnicalReviewInAttendance]);

  useEffect(() => {
    setIsEventTechnical(!!eventOnTechnicalReviewInAttendance);
    return () => setFieldValue('report', '');
  }, []);

  return (
    <Box
      zIndex={usersModalOpen ? '9999' : '9'}
      pt={touched.report && !!errors.report ? '0px' : '8px'}
      m={!usersModalOpen ? '0 16px 16px 16px' : 0}
      bg="white"
      display="flex"
      height="fit-content"
      minHeight={usersModalOpen && '114px'}
      flexDirection="column"
      borderRadius="8px"
      border={`1px solid ${theme.colors.secondary300}`}
    >
      {openWaitingEventModal && (
        <WaitingEventModal
          onToggle={toggleWaitingEventModal}
          event={event}
          drop={handleDropEvent}
        />
      )}

      {openTechnicalReviewEventModal && (
        <TechnicalReviewEventModal
          onToggle={toggleTechnicalReviewEventModal}
          event={event}
          createServiceOrder={isEventTechnical}
        />
      )}

      {touched.report && !!errors.report && (
        <Box
          borderBottom="none"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
          mb="10px"
          px="10px"
          width="100%"
          height="25px"
          bg="warning500"
        >
          <Text color="white">{errors.report}</Text>
        </Box>
      )}
      <Form onSubmit={handleSubmit} noValidate expand={+usersModalOpen}>
        <Box width="100%" ml="5px" height={usersModalOpen ? '100%' : 'auto'}>
          <Mention
            style={{ height: usersModalOpen ? '100%' : 'auto' }}
            inputProps={{ style: { height: usersModalOpen ? '100%' : 'auto' } }}
            value={values.report}
            items={suggestionData}
            name="report"
            displayTransform={onSuggestionSelected}
            onChange={handleChange}
            placeholder={t('details.event-logger.phrase-select-placeholder')}
          />
        </Box>
        {!usersModalOpen && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            bg={theme.colors.secondary50}
            height="54px"
            borderBottomLeftRadius="8px"
            borderBottomRightRadius="8px"
            borderTop={`2px solid ${theme.colors.secondary300}`}
          >
            <KiperButton
              icon={<DropIcon />}
              color="neutral"
              variant="text"
              onClick={handleDropEvent}
            >
              {t('details.event-logger.drop-event')}
            </KiperButton>

            {!eventOnTechnicalReviewInAttendance && (
              <Button
                id="put-it-on-hold"
                icon={<FiClock />}
                type="button"
                color="neutral"
                variant="outlined"
                onClick={toggleWaitingEventModal}
              >
                {event?.onHold
                  ? t('details.event-logger.reschedule-on-hold')
                  : t('details.event-logger.put-it-on-hold')}
              </Button>
            )}

            <Button
              id="put-on-technical-review"
              icon={
                isEventTechnical ? (
                  <MdOutlineEngineering />
                ) : (
                  <MdOutlineConstruction />
                )
              }
              type="button"
              color="danger"
              variant="out"
              onClick={toggleTechnicalReviewEventModal}
            >
              {isEventTechnical
                ? t('details.event-logger.create-service-order')
                : t('details.event-logger.put-on-technical-review')}
            </Button>

            <Button
              onClick={handleSubmit}
              id="finish_event"
              icon={<FiCheck />}
              type="button"
              color={values.report ? 'primary' : 'secondary'}
              variant="contained"
              disabled={!values.report || loading}
            >
              {loading
                ? t('details.event-logger.finalizing')
                : t('details.event-logger.finalize')}
            </Button>
          </Box>
        )}
      </Form>
    </Box>
  );
};

EventRecord.propTypes = {
  drop: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  values: propTypes.object,
  errors: propTypes.object,
  touched: propTypes.object,
  setFieldValue: propTypes.func,
  handleSubmit: propTypes.func,
  loading: propTypes.bool,
  usersModalOpen: propTypes.bool.isRequired,
};

EventRecord.defaultProps = {
  values: null,
  errors: null,
  touched: null,
  setFieldValue: null,
  handleSubmit: null,
  loading: false,
};

export default EventRecord;
