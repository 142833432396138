import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: 'languageOnly',
    fallbackLng: 'pt',
    ns: [
      'common',
      'buttons',
      'event',
      'user',
      'condominium',
      'event_attendance',
      'guided-attendance',
      'modals/my-settings',
      'modals/event-timeline-modal',
      'modals/event-activity-modal',
      'modals/iot-modal',
      'modals/waiting-event-modal',
      'modals/changelog-modal',
      'modals/technical-review-event-modal',
    ],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
